








































  import {Vue, Component, Prop} from 'vue-property-decorator';
  import CusTable from "@/components/table";
  import uploadModel from "./components/uploadModel.vue";
  import MixinEdu from "@/views/education/Mixins/mixin-edu";
  import knowdgeAdd from './components/knowledgeAdd.vue'

  @Component({
    name: "element-mould",
    components: { uploadModel },
    mixins: [MixinEdu]
  })
  export default class extends Vue {
    private subject: any = null
    private tableParam = {
      subjectId: null,
      title:''
    }
    private subjectOptions = []
    $refs!: { table: CusTable, uploadModel: uploadModel };

    private openModel(data:{[key: string]: any} = {}) {
      this.$modal.create({
        component: knowdgeAdd,
        title: '新增知识元模板',
        width: 550,
	      props: {data}
      }).$on('close', () => {
        this.$refs.table.request();
      })
    };
    // 编辑
    private editModel(data) {
      this.subjectOptions.map((e: any) => {
        if (e.id === data.subjectId) {
          data.code = e.code
        }
      })
      this.$router.push({
        path: '/mould/element-moulds/editModel',
        query: {
          ...data,
        }
      })
    }
    private subjectData() {
      this.axios.post('/system/dictionary/queryAll', {typeCode: 'SUBJECT'}).then((res: any) => {
        if (res.json && res.json.length) this.subjectOptions = res.json;
      })
    };

    handleDelete(idx, row) {
      this.selectedArr = [ row ];
      this.batchDeleteHandle(this.$refs.table, '/admin/knowledgeTemplate/batchDelete');
    }

    private async handleChangeStatus(idx, row) {
      let status = row.status == 0 ? 1 : 0
      const res: any = await this.axios.post('/admin/knowledgeTemplate/saveOrUpdate', { id: row.id, status } ,{ headers:{'Content-Type': 'application/json'}});
      if (res.result) {
        this.$message({
          type: 'success',
          message: row.status === 0? '停用成功' : '启用成功'
        });
        this.selectedArr = [];
        this.$refs.table.request();
      } else {
        this.$message({
          type: 'success',
          message: res.msg
        })
      };
    }

    protected created() {
      this.subjectData();
    }
  }
