



















	import { Vue, Component, Prop } from 'vue-property-decorator';
	import { Upload } from "element-ui";

  @Component({
		name: "uploadModel"
	})
	export default class uploadModel extends Vue{
    @Prop({default: () => ({})}) data!: any;
    constructor(props) {
      super(props);
	    this.data.id && Object.keys(this.param).map(i => this.param[i] = this.data[i]);
      this.param.subjectId = this.data.subjectId ? [ this.data.subjectId as never ] : []
    }
	  private subjectOptions = []
	  private subjectCode:string[] = []
	  private param: any = {
			title: '',
			subjectId: '',
			status: 0
	  }	

    private save() {
			if (!this.param.title) {
				this.$message.warning('请输入模板名称！');
				return;
			} else if (!this.param.subjectId) {
				this.$message.warning('请选择学科');
				return;
			} else {
				this.axios.post('/admin/knowledgeTemplate/saveOrUpdate', this.param, { headers: {'Content-Type': 'application/json'}}).then((res: any) => {
					if (res.result) {
						let params = {
							sort: 1,
							level: 1,
							cssType: 1,
							subtype: '1,2,3,4',
							cssContent: '默认标题',
							pid: res.json.id,
							title: '默认标题'
						}
						this.axios.post('/admin/knowledgeTemplateModule/saveOrUpdate', params, { headers: {'Content-Type':'application/json'}}).then((data: any) => {
							if(data.result) {
								this.$message.success('添加成功'),this.$emit('close')
							}
						})
					}
				})
			}
    }
    private subjectData() {
		this.axios.post('/system/dictionary/queryAll', {typeCode: 'SUBJECT'}).then((res: any) => {if (res.json && res.json.length) this.subjectOptions = res.json;})
    }

    protected created() {
      this.subjectData()
    }
	}
